<template>
  <b-card class="mb-0">
    <b-row align-h="between" align-v="center">
      <b-col>
        <h2 class="mb-0">{{ $t('service.helicopter.bases.title') }}</h2>
      </b-col>
      <b-col class="d-flex justify-content-end">
        <app-btn-add v-if="$route.name !== 'productShow'" @click="modeBaseForm = 'add'">
          {{ $t('service.helicopter.bases.add_a_city') }}
        </app-btn-add>
      </b-col>
    </b-row>
    <p class="mb-3 mt-1">{{ $t('service.helicopter.bases.introduction') }}</p>

    <b-card v-for="base in product.bases" :key="base.id" class="border">
      <div class="d-flex justify-content-between">
        <div class="w-50 d-flex justify-content-between">
          <div>
            <div>{{ $t('service.helicopter.bases.city_base') }}</div>
            <h4 class="d-inline">{{ base.cityBase.city }}</h4>
            <feather-icon
              v-if="$route.name !== 'productShow'"
              class="ml-1 mr-2"
              style="cursor: pointer"
              size="18"
              icon="Edit2Icon"
              color="#e1ca93"
              @click=";(modeBaseForm = 'edit'), (selectedBase = base)"
            />
            <app-link-gmap-address class="ml-1 mr-md-5" :query="[base.cityBase.address, base.cityBase.city, base.cityBase.countryCode]" />
          </div>
        </div>
        <div class="d-flex align-items-center">
          <b-form-checkbox v-model="base.active" class="mb-25" name="check-button" switch inline disabled />
          <feather-icon v-if="$route.name !== 'productShow'" style="cursor: pointer" size="18" icon="TrashIcon" color="#ea5455" @click="modalDeleteItem(base.id)" />
          <app-btn-add
            v-if="$route.name !== 'productShow'"
            class="ml-2"
            hide-text="sm"
            @click="
              () => {
                addDepositZoneForm = true
                baseOfDepositZone = { id: base.id, city: base.cityBase.city }
              }
            "
          >
            {{ $t('service.helicopter.bases.add_a_deposit_zone') }}
          </app-btn-add>
        </div>
      </div>

      <app-collapse v-if="base.depositZones.length" class="mt-1" accordion type="border">
        <app-collapse-item :title="$t('service.helicopter.bases.deposits_zones')">
          <app-data-table
            table-name="depozit-zones"
            thead-class="d-none"
            primary-key="id"
            striped
            responsive
            show-empty
            :fields="tableColumns"
            :items="base.depositZones"
            :empty-text="$t('table.no_record_found')"
          >
            <template #cell(dzAddress)="{ item }">
              <span>{{ `${item.address} ${item.postalCode} ${item.city}` }}</span>
            </template>

            <template #cell(actions)="{ item }">
              <div align="right">
                <app-data-table-actions
                  table-name="deposit-zone"
                  display-mode="inline-icon"
                  :show-add="false"
                  :show-duplicate="false"
                  :show-show="false"
                  :show-delete="($route.name !== 'productShow')"
                  :show-edit="($route.name !== 'productShow')"
                  @delete="modalDeleteDZ(base.id, item.id)"
                  @edit="
                    () => {
                      addDepositZoneForm = true
                      baseOfDepositZone = { id: base.id, city: base.cityBase.city }
                      depositZone = item
                    }
                  "
                />
              </div>
            </template>
          </app-data-table>
        </app-collapse-item>
      </app-collapse>
    </b-card>

    <footer-form-service class="mt-3" previous next @click:previous="$emit('previous-tab')" @click:next="$emit('next-tab')" />

    <HelicopterBaseForm v-model="modeBaseForm" :selected-base="selectedBase" @input="selectedBase = null" />
    <DepositZoneForm
      v-model="addDepositZoneForm"
      :base-of-deposit-zone="baseOfDepositZone"
      :deposit-zone="depositZone"
      @input="
        () => {
          baseOfDepositZone = null
          depositZone = null
        }"
    />
  </b-card>
</template>

<script>
import { defineComponent, inject, ref } from '@vue/composition-api'

import AppCollapse from '@/@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@/@core/components/app-collapse/AppCollapseItem.vue'
import AppDataTableActions from '@/components/AppDataTableActions.vue'
import AppDataTable from '@/components/AppDataTable.vue'
import AppLinkGmapAddress from '@/components/AppLinkGmapAddress.vue'
import AppBtnAdd from '@/components/AppBtnAdd.vue'
import HelicopterBaseForm from '../component/HelicopterBaseForm.vue'
import DepositZoneForm from '../component/DepositZoneForm.vue'
import FooterFormService from '../component/FooterFormService.vue'

export default defineComponent({
  name: 'HelicopterBases',

  components: {
    HelicopterBaseForm,
    DepositZoneForm,
    FooterFormService,
    AppCollapse,
    AppCollapseItem,
    AppDataTable,
    AppDataTableActions,
    AppBtnAdd,
    AppLinkGmapAddress,
  },

  setup(_props, ctx) {
    const { $i18n, $bvModal, alert } = ctx.root

    const product = inject('product')
    product.value.fetchBases(ctx.root.$route.params.service_id, ctx.root.$route.query.vid)

    const modeBaseForm = ref(null)
    const addDepositZoneForm = ref(false)
    const selectedBase = ref(null)
    const baseOfDepositZone = ref(null)
    const depositZone = ref(null)

    const tableColumns = [{ key: 'dzAddress', tdClass: 'w-100' }, { key: 'actions' }]

    const modalDeleteItem = baseId => {
      $bvModal
        .msgBoxConfirm($i18n.t('alert.delete_confirmation.message'), {
          title: $i18n.t('alert.delete_confirmation.title'),
          size: 'sm',
          okVariant: 'primary',
          okTitle: $i18n.t('common.yes'),
          cancelTitle: $i18n.t('common.no'),
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            alert($i18n.t('alert.delete.success'))
            product.value.deleteBase(baseId).then(() => {
              product.value.fetchBases()
            })
          }
        })
    }

    const modalDeleteDZ = (baseId, dzId) => {
      $bvModal
        .msgBoxConfirm($i18n.t('alert.delete_confirmation.message'), {
          title: $i18n.t('alert.delete_confirmation.title'),
          size: 'sm',
          okVariant: 'primary',
          okTitle: $i18n.t('common.yes'),
          cancelTitle: $i18n.t('common.no'),
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(isConfirmed => {
          if (isConfirmed) {
            alert($i18n.t('alert.delete.success'))
            product.value.deleteDepositZone(baseId, dzId).then(() => {
              product.value.fetchBases()
            })
          }
        })
    }

    return {
      product,
      modeBaseForm,
      addDepositZoneForm,
      selectedBase,
      depositZone,
      baseOfDepositZone,
      modalDeleteItem,
      modalDeleteDZ,
      tableColumns,
    }
  },
})
</script>
