<template>
  <a
    :href="`https://www.google.com/maps/search/?api=1&query=${compact(query).join('+')}`"
    target="_blank"
    v-on="$listeners"
  >
    <slot>
      <template v-if="$store.getters['app/mdAndUp']">
        {{ $t('service.helicopter.bases.view_in_google_map') }}
      </template>
      <feather-icon
        v-else
        style="cursor: pointer"
        size="18"
        icon="MapIcon"
        color="#e1ca93"
      />
    </slot>
  </a>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import { compact } from 'lodash'

export default defineComponent({
  name: 'AppLinkGmapAddress',

  props: {
    query: {
      type: Array,
      required: true,
    },
  },

  setup() {
    return {
      compact,
    }
  },
})
</script>
