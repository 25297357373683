var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-sidebar',{attrs:{"id":"sidebar-dz","no-header":"","visible":_vm.value},on:{"hidden":function($event){return _vm.$emit('input', null)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h5',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.$t('service.helicopter.bases.add_a_deposit_zone')))]),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":hide}})],1),_c('validation-observer',{ref:"depositZoneFormRef",staticClass:"p-5",attrs:{"tag":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('div',{staticStyle:{"font-size":"13px"}},[_vm._v(_vm._s(_vm.$t('service.helicopter.bases.city_base'))+" :")]),(_vm.baseOfDepositZone)?_c('div',{staticClass:"ml-50 mb-2"},[_vm._v(_vm._s(_vm.baseOfDepositZone.city))]):_vm._e(),_c('validation-provider',{staticClass:"validation-required",attrs:{"name":_vm.$t('common.address'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"address","label":_vm.$t('common.address')}},[_c('b-form-input',{attrs:{"id":"address","state":errors[0] ? false : null,"placeholder":_vm.$t('common.address')},model:{value:(_vm.address.address),callback:function ($$v) {_vm.$set(_vm.address, "address", $$v)},expression:"address.address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{staticClass:"validation-required",attrs:{"name":_vm.$t('address.postal_code'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"postalCode","label":_vm.$t('address.postal_code')}},[_c('b-form-input',{attrs:{"id":"postalCode","state":errors[0] ? false : null,"placeholder":_vm.$t('address.postal_code')},model:{value:(_vm.address.postalCode),callback:function ($$v) {_vm.$set(_vm.address, "postalCode", $$v)},expression:"address.postalCode"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{staticClass:"validation-required",attrs:{"name":_vm.$t('address.city'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"city","label":_vm.$t('address.city')}},[_c('b-form-input',{attrs:{"id":"city","placeholder":_vm.$t('address.city'),"state":errors[0] ? false : null},model:{value:(_vm.address.city),callback:function ($$v) {_vm.$set(_vm.address, "city", $$v)},expression:"address.city"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('enum-select',{attrs:{"label":_vm.$t('common.country'),"text":"text","enum-class":"CountryCode","required":""},model:{value:(_vm.address.countryCode),callback:function ($$v) {_vm.$set(_vm.address, "countryCode", $$v)},expression:"address.countryCode"}}),_c('app-form-actions',{staticClass:"mt-5",attrs:{"form-name":"deposit-zone-form"},on:{"cancel":hide,"reset":function($event){_vm.address = _vm._cloneDeep(_vm.initAddress)}}})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }